<template>
  <div class="loginPageBox">
    <div id="loginPage">
      <div class="img-c">
        <img src="@/assets/img/login/lgbg3.png" alt="">
      </div>
      <div class="fr login_box">
        <div class="projectName">
          智能建造数字教育平台登录
        </div>
        <div class="lp-cont">
          <el-form class="loginForm" ref="ruleFormRef" :model="loginForm"  :rules="rules">
            <el-form-item class="lg-from" prop="username">
              <el-input
                v-model="loginForm.username"
                auto-complete="off"
                placeholder="请输入账号"
              >
              </el-input>
              <!-- <img src="@/assets/img/login/lg-user.png" /> -->
            </el-form-item>
            <el-form-item class="lg-from spwd" prop="password">
              <el-input
                type="password"
                v-model="loginForm.password"
                auto-complete="new-password"
                placeholder="请输入密码"
              >
              </el-input>
              <!-- <img src="@/assets/img/login/lg-password.png" /> -->
            </el-form-item>
            <el-form-item class="codeItem lg-from" prop="isPass">
              <drag-verify
                ref="dragVerify"
                v-model:isPassing.sync="loginForm.isPass"
                background = "#ffffff"
                completedBg="#76c8f6"
                progressBarBg="#76c8f6"
                text="滑动验证"
                successText="验证通过"
                handlerIcon="el-icon-d-arrow-right"
                successIcon="el-icon-check"
                @passcallback="passcallback"
                :width="width"
                :height="height"
                :circle="circle"
                radius="50px"
                textSize="14px"
                textColor="#666"
              ></drag-verify>
            </el-form-item>
            <el-form-item class="lg-btn">
              <button
                type="button"
                class="loginBtn"
                @click="sibmitLogin"
              >登录</button>
            </el-form-item>
            <p>
              <span style="color:#666666;font-size:14px;cursor:pointer;float:left;" @click="forgotPassword">忘记密码</span>
              <span style="color:#76C8F6;font-size:14px;cursor:pointer;float:right;" @click="goRegister">没有账号?去注册</span>
              
            </p>
          </el-form>
        </div>
      </div>
      <el-dialog v-model="centerDialogVisible" title="" width="20%" center :show-close="false" :modal="false">
      <div style="text-align: center;">该账号已被封禁</div>
      <template #footer>
        <span class="dialog-footer">
          <button
                type="button"
                class="loginBtn"
                style="width: 100px;"
                @click="studentClick"
              >确定</button>
        </span>
      </template>
    </el-dialog>
    <div style=" position: absolute;
    bottom: 20px;
    right: 50%;">
         <p style="color:#fff;font-size:14px;text-align:center">推荐浏览器: chrome</p>
      </div>
  </div>
  </div>
  
</template> 
<script>
import {
  reactive,
  toRefs,
  onMounted,
  ref
} from "vue";
import dragVerify from "@/components/commons/dragVerify";
import { useRouter } from "vue-router";
import { setStorage } from "@/js/common";
import { defineComponent ,computed } from "vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import {Front_end_logint} from "@/js/loginApi"
import { getStorage } from "@/js/common";
export default defineComponent({
  setup() {
    const ruleFormRef = ref(null)
    const router = useRouter();
    const store = useStore();
    const validatePhone = (rule,value,callback)=>{
      /*console.log(rule);
      console.log(value);
      console.log(callback);*/
      if (!value){
        callback(new Error('手机号不能为空'));
      }
      //使用正则表达式进行验证手机号码
      if (!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('手机号不正确'));
      }
      callback()
    }
    const validatePass = (rule, value, callback) => {
    if (!value) {
        callback(new Error('请完成滑动验证'))
    } else {
    callback()
  }
  callback()
}
    const state = reactive({
      circle: true,
      centerDialogVisible:false,
      width: 365,
      height: 40,
      loginForm: {
        username: "",
        password: "",
        isPass: false,
      },
      rules:{
        username:[{ required: true, message: '账号不能为空', trigger: 'blur' }],
         password:[{ required: true, message: '密码不能为空', trigger: 'blur' }],
         isPass:[{ validator:validatePass, trigger: 'blur' }],
      },
      
      canNext:computed(()=>{
        return (state.loginForm.username != "" &&
        state.loginForm.password != "" &&
        state.isPass)
      })
    });
    
    const methods = {
      passcallback:()=>{
        state.loginForm.isPass = true;
      },
      sibmitLogin: () => {
       
          ruleFormRef.value.validate((valid) => {
            if (valid) {
              let params  ={
                userName:state.loginForm.username,
                password:state.loginForm.password
              }
              Front_end_logint(params).then(res=>{
                if (res.data.code == 1) {
                  console.log(res);
                  setStorage("username",state.loginForm.username);
                  setStorage("password",state.loginForm.password);
                  let userType = res.data.data.userType
                  setStorage("StudentOrTeacher" , userType) //接口返回字段  1代表老师登录 //2代表学生登录 控制个人中心是老师的还是学生的 
                  let tokenData = res.data.data.token;
                  setStorage("ftoken", tokenData);
                  let userId = res.data.data.id;
                  setStorage("userId", userId);
                  router.push("/home/index");
                  ElMessage({
                      message: "欢迎使用智能建造数字教育平台",
                      type: "success",
                      showClose: true,
                    });
                }else{
                  ElMessage({
                  message: res.data.message,
                  type: "error",
                  showClose: true,
                });
                }
              })

            



              
               
             
            }
          })
         
        // }else{
       
        // }
        
        //  ruleFormRef.value.validate((valid) => {
        //     if (valid) {
        //       let params = {
        //         userName:state.loginForm.cellPhone,
        //         password:state.loginForm.password
        //       }
        //       state.centerDialogVisible = true;
              // Front_end_logint(params).then(res=>{
              //   if (res.data.code == 1) {
              //     setStorage("account",state.loginForm.cellPhone);
              //       setStorage("password",state.loginForm.password);
              //   ElMessage({
              //     message: "欢迎使用智慧建造数字教育平台",
              //     type: "success",
              //     showClose: true,
              //   });
              //  let tokenData = res.data.data;
              //  setStorage("ftoken", tokenData);
              //  if(getStorage('bclist')&&getStorage('bclist').length){
              //   router.go(-1)
              //  }else{
              //   router.push("/");
              //  }
                
              //   }else{
              //      ElMessage({
              //     message: res.data.message,
              //     type: "error",
              //     showClose: true,
              //   });
              //   }
                
              // })
              
        //     } else {
        //     console.log("未通过");
        //     }
        // });
        // if(state.canNext && state.loginForm.password && state.loginForm.cellPhone){
        //   if (!state.canNext || state.loginForm.password != "sx123456" || state.loginForm.cellPhone != "实训演示") {
        //       ElMessage({
        //         message: "账号或密码错误",
        //         type: "error",
        //         showClose: true,
        //       });
        //     return;
        //   }else{
        //     setToken();
        //     router.push("/");
        //     console.log(router)
        //   }

        // }
      },
      goRegister:() => {
        // state.centerDialogVisible = true;
        router.push("/registration");
      },
      studentClick:() =>{
        state.centerDialogVisible = false;
      },
      teacherClick: () =>{
        router.push("/techarRegister");
      },
      // 忘记密码
      forgotPassword:() =>{
        router.push("/forgotPassword");
      },
    };
    onMounted(() => {
        store.commit("SetnarbarMenuActive", 1);
        setStorage("narbarMenuActive", 1);
    });

    return {
      ...toRefs(state),
      ...methods,
      ruleFormRef,
    };
  },
  components:{
    dragVerify
  }
});
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.lg-btn {
  width: 365px;
  height: 42px;
  margin: 40px 0 0 0;
  position: relative;
}

.projectName {
  padding: 78px 0 80px 0;
  position: relative;
  height: 48px;
  font-size: 34px;
  font-weight: 600;
  color: #333333;
  line-height: 48px;
  text-align: center;
}
.loginPageBox{
    width: 100%;
    height: 100%;
    background-color: #F7F8FA;
}
#loginPage {
    position: absolute;
    top: 50%;
    left: 50%;
    width:85%;
    height:90%;
    padding:20px;
    text-align:center;
    transform: translate(-50%, -50%);
  .img-c{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    overflow-y: hidden;
    img{
      top:0;
      left:0;
      width:100%;
      height:100%;
      // object-fit: cover;
    }
  }

  .login_box {
    width: 485px;
    height:485px;
    position:absolute;
    top:40%;
    right:5%;
    transform: translate(0,-50%);
    // background: #fff;
  }

  .lp-c {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 99;
  }

  .lp-cont {
    position: relative;
    padding: 0 60px;
  }
  .lg-logo {
    position: absolute;
    top: -15%;
    left: 50%;
    transform: translate(-50%, -15%);
  }
  .lg-from {
    display: flex;
    justify-content: center;
  }
  .lg-from img {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  .el-form-item {
    margin-bottom: 20px;
    &.codeItem {
      width: 365px;
      position: relative;
      height: 40px;
    }
    &.spwd {
      margin: 20px 0 20px;
    }
  }
  /* 验证码 */
  .codeItem {
    position: relative;
  }
  .emCode {
    box-sizing: border-box;
    position: absolute;
    top: 11px;
    left: 80%;
    margin: 0 0 0 -132px;
    width: 106px;
    height: 37px;
    box-sizing: border-box;
    background: #0a2138;
    cursor: pointer;
    .s-canvas {
      height: 100%;
      canvas {
        height: 100%;
        width: 100%;
        opacity: 1;
      }
    }
  }
  /* 登录按钮 */
  .loginBtn {
    width: 370px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    line-height: 42px;
    text-align: center;
    background: #76C8F6 ;
    color: #fff;
    border: none;
    border-radius: 21px;
    &.gray {
      background: #666;
      color: #cecece;
      cursor: default;
    }
  }
  :deep(.login_box){
    .el-input{
      // background: #fff;
    }
    .el-checkbox__inner {
      border: 1px solid #6fe9ef;
      background-color: transparent;
    }
    .el-input--prefix {
    }
    .el-input--prefix .el-input__inner {
      padding-left: 70px;
      background-size: 100% 100%;
      height: 50px;
      border: none;
      margin: -10px 0;
      width: 410px;
      opacity: 0.6;
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
    span.el-input__prefix {
      transform: translate(0, -50%);
      width: 25px;
      height: 25px;
      left: 35px;
      top: 50%;
    }
    .el-form-item__content {
      display: flex;
      justify-content: center;
      width: 100%;
      line-height: 60px;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #16d0ff;
    }
    .el-input__inner {
      color: #666;
      background: #fff;
      border-radius: 50px;
      border: 1px solid #E1E1E1;
    }

  }
}
</style>